import { AfterViewInit, Component, ElementRef, input, signal, viewChild } from '@angular/core';
import { IGame } from '@libs/interfaces';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { inOutAnimation } from '@libs/animations/app.animations';
import { GamesService } from '@libs/app.services/casino/casino-games.service';
import { CasinoStoreService } from '@libs/app.services/store/casino-store.service';
import { GameImageUrl } from '@libs/helper/casino/games.helper';

@Component({
  selector: 'app-game-chip-simple',
  templateUrl: './game-chip-simple.html',
  styleUrls: ['./game-chip-simple.scss'],

  imports: [CommonModule],
  animations: [inOutAnimation],
})
export class GameChipSimpleComponent implements AfterViewInit {
  game = input<Partial<IGame>>();
  backgroundImage = viewChild<ElementRef>('backgroundImage');
  loading = signal(false);
  providerUrl$: Observable<string>;
  constructor(
    private router: Router,
    private service: GamesService,
    private casinoStoreService: CasinoStoreService,
  ) {}
  ngAfterViewInit() {
    this.backgroundImage().nativeElement.style.backgroundImage = `url("${GameImageUrl(this.game())}")`;
  }
  expand() {
    this.loading.set(true);
    this.expandCasino();
  }
  private expandCasino() {
    this.service
      .addGameStage(this.game() as IGame)
      .pipe(first())
      .subscribe();

    this.casinoStoreService.closeSearcher();
    setTimeout(() => {
      this.router.navigateByUrl('/casino/stage');
    }, 300);
  }
}
